import { computed, inject, Injectable, Signal, signal } from '@angular/core';

import { Store } from '@ngrx/store';
import { selectAuthUser, selectUserRoles } from '../state/selectors/auth.selectors';
import { first, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '@core/models/users';

@Injectable({
  providedIn: 'root',
})
export class WhoisUserService {
  store = inject(Store);
  _role = signal<User.UserRole | null>(null);
  _user = signal<User.User | null>(null);

  constructor() {
    this.store
      .select(selectUserRoles)
      .pipe(
        takeUntilDestroyed(),
        first(Boolean),
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((userRoles: User.UserRole[]) => userRoles[0])
      )
      .subscribe((role) => this._role.set(role));

    this.store
      .select(selectAuthUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user) => this._user.set(user));
  }

  role(): Signal<User.UserRole | null> {
    return computed(() => this._role());
  }

  user(): Signal<User.User | null> {
    return computed(() => this._user());
  }

  isUser(): boolean {
    return this.role()() === User.UserRole.USER;
  }

  isNativa(): boolean {
    return this.role()() === User.UserRole.ADMIN || this.role()() === User.UserRole.NATIVA;
  }

  isAuthenticated(): boolean {
    return this.role() !== null && this._user() !== null;
  }
}
